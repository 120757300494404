const initialState = {
  urls: {
    search: '/api/v1/search?type=materialSyllabus',
    'search-topic-seo': '/api/v1/search?type=material&keywords={{keywords}}',
    'search-syllabus-subject':
      '/api/v1/search?type=subject&instituteId={{instituteId}}&specializationId={{specializationId}}&semesterId={{semesterId}}&$limit={{limit}}&$skip={{skip}}',
    'search-preferred-subject':
      '/api/v1/search?type=subject&instituteId={{instituteId}}&specializationId={{specializationId}}&semesterId={{semesterId}}&courseId={{courseId}}',
    'search-syllabus-chapter':
      '/api/v1/search?type=chapter&subjectId={{subjectId}}',
    'search-syllabus':
      '/api/v1/search?type={{type}}&instituteId={{instituteId}}&specializationId={{specializationId}}&subjectId={{subjectId}}&chapterId={{chapterId}}&$limit={{limit}}&$skip={{skip}}',
    'search-syllabus-topic':
      '/api/v1/search?type=chapter&subjectId={{chapterId}}',
    'suggested-material':
      '/material/v1/{{materialId}}/suggestions?$limit={{limit}}&$skip={{skip}}',
    'suggested-subject': '/material/v1/{{materialId}}/recomended-subjects',
    'material-details': '/material/v1/{{materialId}}/details',
    'material-pages': '/material/{{materialId}}/pages',
    'material-bookmark-pages':
      '/material/{{materialId}}/bookmark/v1?$skip={{skip}}&$limit={{limit}}',
    'material-subscriptions': '/material-subscriptions',
    'remove-material-subscriptions':
      '/material-subscriptions/{{username}}/{{materialId}}',
    'abuse-reports':
      'abuse-reports/view-all?reporter={{reporterId}}&objectId={{materialId}}',
    comment:
      '/comments/material/{{materialId}}?$limit={{limit}}&$skip={{skip}}',
    'post-comment': '/comments/material/{{materialId}}',
    'edit-comment': '/comments/material/{{materialId}}/{{commentId}}',
    'delete-comment': '/comments/material/{{materialId}}/{{commentId}}',
    'like-comment': '/comment/{{commentId}}/like',
    'remove-like-comment': '/comment/{{commentId}}/unlike',
    'reply-comment-list': 'comment/{{commentId}}/replies',
    'reply-comment': 'comments/material/{{materialId}}',
    courses: '/courses',
    institute: '/institute/viewall?active=true&approved=true&deleted=false',
    subject: '/subject/v1/view-all',
    semester: '/academic-sessions?$sort[name]=1',
    'active-material-details': '/my-uploads/active/{{username}}',
    'upload-material-details':
      '/my-upload?{{filterByName}}&approved={{approved}}&deleted={{deleted}}',
    'disapprove-material-details':
      '/my-uploads/dis-approved/{{username}}?$limit=10',
    'draft-material-details':
      '/my-uploads/drafted/{{username}}?$limit=10&$sort[_id]=-1',
    'rejected-material-details':
      '/my-uploads/rejected/{{username}}?$limit=10&$sort[_id]=-1',
    'popular-contributors': '/popular-contributors',
    'most-viewed-download-material':
      '/api/v1/subject-material?$sort[views]=-1&$limit={{limit}}&$skip={{skip}}',
    'faq-category':
      'api/v1/faq-category?name[$search]={{type}}&isDeleted=false&published=true&$skip=0&$limit=50',
    'faq-list-by-categoryid':
      '/api/v1/faq-question?categoryId={{categoryId}}&isDeleted=false&published=true&$skip={{skip}}&$limit={{limit}}',
    'recommended-notes':
      '/api/v1/recommended-material?institute={{institute}}&specialization={{specialization}}',
    'trending-notes':
      '/api/v1/trending-notes?institute={{institute}}&specialization={{specialization}}&subject={{subject}}',
    'continue-reading':
      '/recent-activities/{{username}}?$skip={{skip}}&$limit={{limit}}',
    'my-favorite':
      '/material-subscription/{{username}}?$skip={{skip}}&$limit={{limit}}',
    'preferred-subject':
      '/api/v1/preferred-subjects?userId={{userId}}&active=true&$skip={{skip}}&$limit={{limit}}',
    'check-user': '/api/v1/check-user',
    user: '/api/v1/users',
    'user-update': '/api/v1/users/{{userId}}',
    'send-otp': '/api/v1/send-otp',
    'verify-otp': '/api/v1/verify-otp',
    'reset-password': '/api/v1/reset-password',
    'get-semesters': '/academic-sessions?{{filterByName}}',
    'get-specializations': '/specialization/view-all',
    'get-institutes': '/institute/viewall',
    'get-courses': '/courses?active=true&approved=true&deleted=false',
    'set-preference': '/api/v1/user-preference',
    'otp-login': '/api/v1/otp-login',
    specialization:
      '/specialization/view-all?active=true&approved=true&deleted=false&$limit={{limit}}&$skip={{skip}}{{filterByName}}',
    year: '/years?$sort[year]=-1&$limit={{limit}}&$skip=0',
    'verify-google-token': '/api/v1/verify-google-token',
    'material-upload': '/upload',
    'material-upload-patch': '/upload/add-file',
    'check-upload-progress': '/upload/check-queue-progress/{{materialId}}',
    'material-update': '/material/{{materialId}}/{{action}}',
    'search-by-type': '/api/v1/search?type={{type}}',
    'user-activities':
      '/api/v1/user-activities?$limit=20&$skip=0&userId={{userId}}&$populate=activityId&$populate=userId&$sort[_id]=-1',
    'milestone-details': '/api/v1/get-activity-details?userid={{userId}}',
    'my-carts': '/api/v1/carts',
    'apply-coupon': '/api/v1/apply-coupon',
    'remove-cart-items': '/api/v1/remove-cart-items',
    'delete-cart-item': '/api/v1/delete-cart-item',
    milestone: '/api/v1/get-all-task?userid={{userId}}',
    testimonials: '/api/v1/testimonial?url={{{url}}}&active=true',
    'material-rating': 'rate/material/{{materialId}}',
    'ping-online': '/ping-online',
    redemption: '/api/v1/redemption',
    'my-active-subscription':
      '/api/v1/myfeature?$populate=featureId&active=true&$sort[createdAt]=-1',
    'verify-referral-code': '/api/v1/check-referral-code',
    'activity-details': '/api/v1/getActivityDetails',
    'get-client': '/api/v1/client?$sort[updatedAt]=-1',
    'topic-chapter': 'api/v1/topic?$limit=2000',

    'get-slug-details': '/slug/v1?slug={{slugName}}',
    'get-institute-syllabus':
      '/api/v1/search?type=specialization&instituteId={{instituteId}}&$limit={{limit}}&$skip={{skip}}',
    'get-semester-syllabus':
      'api/v1/semester-by-syllabus?instituteId={{instituteId}}&specializationId={{specializationId}}&courseId={{courseId}}',
    'get-subject-syllabus':
      'api/v1/subject-by-syllabus?instituteId={{instituteId}}&specializationId={{specializationId}}&courseId={{courseId}}&academicSessionId={{semesterId}}',
    material: '/material/v1/{{materialId}}',
    'material-contributor':
      '/material/{{materialId}}/contributors/{{contributorId}}',
    'get-material-contributor': 'material/{{materialId}}/contributors',
    reference: '/{{materialType}}/{{materialId}}/references/{{referenceId}}',
    'reference-list': '/{{materialType}}/{{materialId}}/references',
    'bulk-rotation': '/bulk-rotation',
    'bulk-rotation-status': '/bulk/status?key={{key}}',
    'delete-material': '/delete-multiple-pages?materialId={{materialId}}',
    'move-pages': '/material/{{materialId}}/move-pages',
    'cut-pages': '/material/{{materialId}}/cut-pages',
    'copy-pages': '/material/{{materialId}}/copy-pages',
    'embed-material': '/material/{{materialId}}/embed/video',
    'material-add-view-bookmark': '/material/{{materialId}}/bookmark/v1',
    'split-material': 'split-material',
    'client-by-Id': '/api/v1/client',
    'material-category': '/material-category',
    'material-approve': '/material/{{materialId}}/approve',
    'material-disapprove': '/material/{{materialId}}/disapprove',
    'material-delete': '/material/{{materialId}}/delete',
    'material-un-delete': '/material/{{materialId}}/undelete',
    'change-client': '/material/{{materialId}}/change-client',
    'material-activate': '/material/{{materialId}}/activate',
    'material-deactivate': '/material/{{materialId}}/deactivate',
    'material-handwritten': '/material/{{materialId}}/handwritten',
    'material-offline': '/material/{{materialId}}/offline',
    'material-permission': '/material/{{materialId}}/change-permission',
    'bookmark-delete': 'material/{{materialId}}/bookmark/v1/{{bookmarkId}}',
    'update-material-page': '/material/{{materialId}}/pages/{{pageId}}',
    'search-institute': '/api/v1/search?type=institute{{keywords}}',
    'search-specialization':
      '/api/v1/search?type=specialization{{keywords}}{{instituteId}}',
    'search-subject':
      '/api/v1/search?type=subject{{keywords}}{{instituteId}}{{specializationId}}',
    'search-chapter':
      '/api/v1/search?type=chapter{{keywords}}{{instituteId}}{{courseId}}{{specializationId}}{{subjectId}}',
    'search-topic':
      '/api/v1/search?type=topic{{keywords}}{{instituteId}}{{courseId}}{{specializationId}}{{subjectId}}{{chapterId}}',
    'search-subtopic':
      'api/v1/topic?parent={{parentId}}&deleted=false&$limit={{limit}}',
    'search-semester':
      '/api/v1/search?type=semester{{keywords}}{{instituteId}}{{specializationId}}',
    'search-courses': '/api/v1/search?type=course{{keywords}}{{instituteId}}',
    'original-files': '/material/{{materialId}}/original-files',
    'search-classroom-center': '/api/v1/classroom-center?active=true',
    'search-classroom-template': '/api/v1/classroom-template',
    transactions: '/api/v1/reward_points?{{userId}}$sort[updatedAt]=-1',
    'report-abuse': '/abuse-report/{{type}}/{{objectId}}',
    'lca-status': '/api/v1/get-ca-status?username={{username}}',
    'updata-lca': '/api/v1/ambassador',
    'refer-faq':
      'api/v1/faq-category?name[$search]={{type}}&isDeleted=false&published=true&$skip=0',
    'invite-user': '/api/v1/invite-user',
    'create-refercode': '/api/v1/create-referral-code',
    'material-watermark': '/api/v1/{{type}}/watermark/{{materialId}}',
    announcement: '/api/v1/announcements?deleted=false&active=true',
    'ca-details': '/api/v1/get-ca-details?userId={{userId}}',
    'job-category': '/api/v1/job-category?$sort[name]=1&$limit=50&active=true',
    'job-type': '/api/v1/job-type?$sort[name]=1&&$limit=50&active=true',
    'job-search':
      '/api/v1/jobs?$sort[updatedAt]=-1&$limit=50&$skip=0&$populate=categoryId&$populate=jobTypeId&$populate=locationIds&active=true',
    'job-apply': '/api/v1/job/apply/send-mail-attachment',
    'analytics-details': '/api/v1/upload-data',
    'earning-details':
      '/api/v1/user-activities?userId={{userId}}&lastdays={{lastdays}}',
    countries: '/api/v1/countries',
    'search-users': '/api/v1/search?type=user',
    'order-details': '/api/v1/get-bought-product/{{orderId}}',
    'training-name': '/api/v1/training-calender?venueExist=false&Scheduled=true',
    'venue-name': '/api/v1/venues',
    'district-name': '/api/v1/district?$limit=50',
    'mandal-name': '/api/v1/mandal?$limit=50',
    'designation-name': '/api/v1/training-designation?$limit=50',
    notification:
      '/user-notification-details?user={{user}}&type=pushNotification&$limit={{limit}}&$sort[createdAt]=-1',
    'mark-as-read': '/mark-all-as-read/{{notificationId}}',
    'notification-all-read': '/mark-all-as-read',
    'rejection-reasons': '/rejection-reason?$limit=30&$skip=0',
    'img-scroll': '/api/v2/institute-list?&$limit={{limit}}&$skip={{skip}}',
    'apply-referral': 'api/v1/check-referral-code',
    'get-user': 'api/v1/get-user/{{userName}}',
    'content-setting': '/content/settings',
    'user-profile': 'api/v1/profile/{{profileId}}',
    'login-details': 'api/v1/login-details',
    'content-pages': '/content-pages?webPageId={{id}}',
    'web-pages': '/web-pages/v1/view-all',
    'bulk-page-remove': '/pages-bulk-remove/{{materialId}}',
    'verify-password': '/api/v1/verify-password',
    'faq-question':
      '/api/v1/faq-question?categoryId=${{categoryId}}&isDeleted=false&published=true&$skip=${{skip}}&$limit=50',
    'faq-search':
      'api/v1/faq-question?$populate=categoryId&isDeleted=false&published=true&$skip=0',
    'faq-reach-out': '/faq-reach-out',
    'product-details': '/api/v1/features/{{productId}}',
    'product-purchase': 'api/v1/direct-Product-Payment',
    'vendor-list': 'api/v1/vendor',
    'vendor-courses':
      'api/v1/vendor-course?$populate=category&$populate=ribbon',
    'vendor-course-details':
      'api/v1/vendor-course/{{courseId}}?$populate=variants',
    'download-invoice': '/billing/v1/invoices/{{orderId}}/download',
    'save-material': '/api/v1/saved-material',
    'get-banners': '/ad-banner?&$sort[position]=1',
    'job-categories': '/categories?&$sort[position]=1',
    enquiry: '/enquiries',
    'mentor-videos': '/upload-video?&$sort[position]=1',
    'delete-my-downloads': '/delete-my-downloads',
    'active-classroom': '/api/v1/classroom/joined',
    'find-classroom': '/api/v1/class-room?$populate=classroomTemplateId',
    'member-list': '/api/v1/class-member',
    'edit-member':
      '/api/v1/class-member/{{memberId}}?$populate=classroomId&$populate=userId&$populate=joinedById',
    'users-list': '/api/v1/users',
    'live-lecture': '/api/v1/live-lecture',
    'classroom-post': '/api/v1/post?$sort[activationTime]=-1',
    'classroom-post-like': '/api/v1/post-like',
    'classroom-post-comment':
      'api/v1/comment?$sort[creationTime]=-1&postId={{postId}}',
    quiz: 'exam-for-classroom?status={{status}}&$sort[updatedAt]=-1&groups={{classroomId}}&$populate=section',
    'update-downloadable': '/material/{{materialId}}/downloadable',
    assignment: '/api/v1/assignment',
    'exam-for-classroom': '/exam-for-classroom',
    submission: 'api/v1/submission',
    'upcoming-quiz':
      'api/v1/exam/?status=1&$limit={{limit}}&$skip={{skip}}&$sort%5BupdatedAt%5D=-1&$populate=subject&examAvailability=availableInSpecificTime',
    'bulk-remove-classroom-members': 'api/v1/bulk-remove-member',
    'add-member':
      '/api/v1/bulk-add-member?$populate=classroomId&$populate=userId&$populate=joinedById',
    modules: '/api/v1/class-section',
    'classroom-materials':
      '/api/v1/classContent?&$populate=addedById&$populate=classroomId&$populate=classSectionId',
    'update-module': '/api/v1/class-section/{{sectionId}}',
    'edit-classroom': '/api/v1/class-room/{{classroomId}}',
    'host-provider': '/host-provider',
    'create-section-question': '/create-section-question',
    'edit-section-question': '/create-section-question/{{questionId}}',
    'questions-list': '/api/v1/questions-of-exam-sections?$populate=questionId',
    'publish-quiz': '/exam-for-classroom/{{examId}}',
    'classroom-requests': '/api/v1/class-request',
    'accept-reject-request': '/api/v1/class-request/{{requestId}}',
    'request-classroom': 'api/v1/class-request?classroomId={{classroomId}}',
    'cancel-request':
      'api/v1/class-request/{{requestedId}}?classroomId={{classroomId}}',
    'delete-classContent': '/api/v1/classContent/{{contentId}}',
    'appeared-user-list':
      '/api/v1/appeared-user-list/{{examId}}?$sort[createdAt]=1',
    'subject-wise-statistics': '/api/v1/search?type=subjectWiseMaterial',
    'search-state': '/api/v1/states',
    'rearrange-contents': 'api/v1/rearrange-contents',
    profile: '/api/v1/profile/{{profileId}}',
    'get-user-by-username': '/api/v1/get-user/{{username}}',
    'life-event': 'api/v1/user-life-events',
    'user-upload': 'api/v1/upload',
    exam: '/api/v1/exam/{{examId}}?$populate=course&$populate=institute&$populate=section&$populate=semester&$populate=specialization&$populate=subject&$populate=topic&$populate=candidates&$populate=groups&$populate=examiner',
    'exam-appearance': '/api/v1/exam-appearance',
    'resume-exam': '/api/v1/{{appearanceId}}/resume-exam',
    'save-answer': '/api/v1/exam-appearance/{{appearanceId}}',
    'rate-exam': '/api/v1/exam-rating',
    'appeared-questions':
      '/api/v1/get-all-appeared-qus-ans/{{appearanceId}}?$populate=questionId&$populate=examId&$populate=userId',
    'add-remark':
      '/api/v1/get-all-appeared-qus-ans/{{appearanceId}}?questionId={{questionId}}',
    'get-exams': '/api/v1/exam',
    'auto-evaluate': '/api/v1/check-subjective-question',
    'question-in-section': '/api/v1/question-in-exam-section',
    'question-of-section': '/api/v1/questions-of-exam-sections',
    'generate-question-variant': '/api/v1/create-question-variants',
    'marking-status': '/api/v1/change-marking-status/{{appearanceId}}',
    'date-wise-subject-view': '/api/v1/date-wise-subject-view',
    'cognitive-ability-analytics': '/api/v1/cognitive-ability-analytics',
    'learning-objective-analytics': '/api/v1/learning-objective-analytics',
    'topic-cognitive-ability':
      '/topic-cognitive-ability?topicId={{topicId}}&$populate=cognitiveAbilityId',
    'topic-learning-objective':
      '/topic-learning-objective?topicId={{topicId}}&$populate=learningObjectiveId',
    'print-question-paper': '/download-question-paper',
    'get-client-product': '/api/v1/products/{{id}}',
    'get-client-subscription': '/api/v1/subscriptions/{{id}}',
    'get-purchase-detail':
      '/api/v1/purchased?orderId={{orderId}}&$populate=transactionId',
    'client-transactions': '/api/v1/transaction',
    'product-purchased':
      '/api/v1/purchased?$populate=transactionId&$populate=productId&$populate=customerId',
    'competitive-exam': '/api/v1/competitive-exam',
    'competitive-subject': '/api/v1/competitive-subject',
    'competitive-topics': '/api/v1/competitive-topics',
    attendance: '/api/v1/attendance',
    'paid-material': '/api/v1/paid-material/{{materialId}}',
    'mentorship-info': '/api/v1/check-mentor',
    'log-subject-survey': 'api/v1/log-subject-survey?subjectId={{subjectId}}',
    'get-survey-form':
      'api/v1/show-survey-forms',
    encounters: '/api/v1/encounter-type',
    'my-encounters-types': '/api/v1/get-encounter-types',
    'get-encounters': '/api/v1/log-observations',
    'edit-encounter': '/api/v1/log-observations/{{id}}',
    subjects: '/api/v1/subject',
    hierarchy: '/api/v1/hierarchy',
    hierarchyType: '/api/v1/hierarchy-type',
    'feedback-members': '/feedback-user-list',
    'download-quiz-report': '/api/v1/bulk-quiz-report-download',
    'getLmsHierarchyTypes': '/api/v1/lms-hierarchytypes',
  },
};

const storeConfig = (state = initialState, action: { type: any }) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default storeConfig;

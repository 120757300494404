import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_REFRESH_SUCCESS,
  USER_REFRESH_FAIL,
  GET_USER_CLIENT,
  GET_USER_PERMISSION,
  GET_USER_PREFERENCE,
  UPDATE_USER_DATA,
  LOGOUT,
  GET_USER_NOTIFICATIONS
} from 'app/redux/constants';
import { Platform } from 'react-native';
import { setAsyncStorageData } from '../../util/helper';

//set from cookie or async storage
const setUser = async (user: any) => {
  if (Platform.OS === 'web') {
    await setAsyncStorageData('user', JSON.stringify(user));
  }
};

const initialState = null;

const loggedInUser = (
  state = initialState,
  action: {
    type: any;
    payload: {
      data: { user: any };
    };
    asyncDispatch: (arg0: {
      type: string;
      payload?: { client: string; request: { url: string; method: string } };
    }) => void;
    error: { response: any; data: any };
  }
) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      setUser(action.payload.data.user);
      return action.payload.data.user;
    }
    case LOGIN_FAIL: {
      return {
        error:
          action.error.response?.data?.message ||
          'The password you entered is incorrect. Please try again',
      };
    }
    case USER_REFRESH_SUCCESS: {
      const { _id, clientId } = action.payload.data.user;
      setTimeout(
        () =>
          {action.asyncDispatch({
            type: GET_USER_CLIENT,
            payload: {
              client: 'sso',
              request: {
                url: `/api/v1/client?&clientId=${clientId}`,
                method: 'GET',
              },
            },
          }),
        action.asyncDispatch({
          type: GET_USER_PERMISSION,
          payload: {
            client: 'sso',
            request: {
              url: '/api/v1/permission-list',
              method: 'GET',
            },
          },
        }),
        action.asyncDispatch({
          type: GET_USER_PREFERENCE,
          payload: {
            client: 'sso',
            request: {
              url: `/api/v1/user-preference?userId=${_id}`,
              method: 'GET',
            },
          },
        }),

        // action.asyncDispatch({
        //   type: GET_USER_CARTS_DETAILS,
        //   payload: {
        //     client: 'billing',
        //     request: {
        //       url: `/api/v1/carts?completed=false&username=${username}`,
        //       method: 'GET',
        //     },
        //   },
        // }),

        action.asyncDispatch({
          type: GET_USER_NOTIFICATIONS,
          payload: {
            client: 'notification',
            request: {
              url:`/user-notification-details?user=${_id}&type=pushNotification&$limit=50&$sort[createdAt]=-1`,
              method: 'GET',
            },
          },
        }),

        action.asyncDispatch({
          type: 'GLOBAL_LOADED',
        });},
        5
      );
      return action.payload.data.user;
    }
    case USER_REFRESH_FAIL: {
      if(action.error.data === 'Network Error'){
        return state;
      }
      setTimeout(() => {
        action.asyncDispatch({
          type: 'GLOBAL_LOADED',
        });
        action.asyncDispatch({
          type: LOGOUT,
          payload: {
            client: 'sso',
            request: {
              url: 'api/v1/logout',
              method: 'GET',
            },
          },
        });
      }, 10);

      return null;
    }
    case UPDATE_USER_DATA: {
      return { ...(state ? state : {}), ...action.payload };
    }
    // case LOGOUT_SUCCESS: {
    //   return initialState;
    // }
    // case 'LOGOUT_FAIL': {
    //   return initialState;
    // }
    default: {
      console.log('Action received',action.type)
      return state;
    }
  }
};

export default loggedInUser;
